@font-face {
    font-family: 'Nunito-Regular';
    src: url('../fonts/Nunito-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-Bold';
    src: url('../fonts/Nunito-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-ExtraBold';
    src: url('../fonts/Nunito-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-SemiBold';
    src: url('../fonts/Nunito-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Quicksand-Regular';
    src: url('../fonts/Quicksand-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Quicksand-Medium';
    src: url('../fonts/Quicksand-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Quicksand-Bold';
    src: url('../fonts/Quicksand-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@media print {
    .noPrint {
        display: none;
    }
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type=input],
input[type=tel],
input[type=password] {
    font-size: 14px;
}

input[type=text] {
    font-size: 14px;
    color: grey;
    padding-left: 30px;
}

input[type=checkbox] {
    -webkit-appearance: checkbox;
}

input[type=radio] {
    -webkit-appearance: radio;
}

.backButton {
    border-radius: 22px;
    padding: 5px;
}

.modal-dialog-centered {
    width: 70%;
    margin-left: 15%;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 30px;
    color: #29225c;
}

.swiper-scrollbar-drag {
    background-color: #29225c;
}

.swiper-pagination-bullet-active {
    background-color: #29225c;
}

body {
    width: 100%;
    height: 100%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -o-text-size-adjust: none;
    text-size-adjust: none;
    font-family: 'Nunito-Regular';
}

body {
    padding-top: env(safe-area-inset-top);
}


.swiper {
    z-index: 0 !important;
}


.fotogaleri-load {
    height: 97px;
    border-radius: 20px;
    background: linear-gradient(90deg, rgba(252, 252, 252, 1) 0%, rgb(213, 213, 211) 50%, rgba(244, 245, 245, 1) 100%);
    display: inline-block;
    text-align: center;
    width: 100%;
    padding-top: 55px;
    margin-bottom: 20px;
    animation: blink 1s linear infinite;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


.fotogaleri-load-vitrin {
    height: 130px;
    border-radius: 20px;
    background: linear-gradient(90deg, rgba(252, 252, 252, 1) 0%, rgb(213, 213, 211) 50%, rgba(244, 245, 245, 1) 100%);
    display: inline-block;
    text-align: center;
    width: 45%;
    margin-left: 3%;
    padding-top: 55px;
    color: rgb(210, 210, 210);
    margin-bottom: 20px;
    animation: blinks 1s linear infinite;
}

@keyframes blinks {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}



/* account */

.account .name {
    position: fixed;
    top: 55px;
    background-color: #29225c;
    width: 100%;
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
    text-align: center;
}

.account .menus {
    margin-top: 130px;
    width: 100%;
}

.account .menus div {
    border: 2px solid rgb(220, 220, 220);
    margin-bottom: 20px;
    width: 44%;
    margin-left: 4%;
    float: left;
    height: 100px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-family: 'Nunito-Bold';
}

/* account finish */


/* newbrands */
.searchpage .row .col-sm-6,
.newBrands .row .col-sm-6 {
    display: flex !important;
    justify-content: center !important;
}

.basketAdd {
    background-color: #29225c;
    color: white;
    padding: 6px 20px;
    font-size: 14px;
    margin-top: 10px;
    border-radius: 10px;
}

.productName {
    text-align: center;
    color: grey;
    height: 52px;
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* newbrands finish */

/* Search */

.search .urunler {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: auto auto;
}

.search .urunler img {
    width: 100px;
}

.search .urunler span {
    font-size: 12px;
    color: grey;
    line-height: 13px;
}

.search .urunler small {
    font-size: 10px;
    color: rgb(222, 222, 222);
}

.search .urunler button {
    font-size: 12px;
    border: 0px;
    background-color: blue;
    border-radius: 20px;
}

.search .urunler .item {
    max-height: 300px;
    min-height: 300px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

/* Search finish */



/* contracts */

.contracts .header {
    height: 50px;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid rgb(220, 220, 220);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
}

.contracts .header span {
    font-size: 18px;
    font-family: 'Quicksand-Medium';
}

.contracts .content {
    font-size: 14px;
    /* margin: 70px 20px; */
}

/* contracts finish */


/* login */

.login .girisbtn {
    border-radius: 20px;
    border: 0;
}

.login .uyebtn {
    border-radius: 20px;
}

/* login finish */


/* pay */

.pay .odeme {
    margin-top: 90px;
    width: 90%;
    margin-left: 5%;
}

.pay .odeme .odeme-tutar {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    align-items: center;
}

.pay .odeme .odeme-tutar div {
    margin-left: 2px;
    margin-right: 2px;
}

.pay .odeme .odeme-tutar .ana input {
    width: 80px;
}

.pay .odeme .odeme-tutar .kusur input {
    width: 40px;
}

.pay .odeme-sekli {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    font-size: 14px;
}

/* pay finish */


/* SWİTCH */

.address-edit .switch,
.pay .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
}

.address-edit .switch input,
.pay .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.address-edit .switch .slider,
.pay .switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.address-edit .switch .slider:before,
.pay .switch .slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 0px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.address-edit .switch input:checked+.slider,
.pay .switch input:checked+.slider {
    background-color: #29225c;
}

.address-edit .switch input+.slider,
.pay .switch input:focus+.slider {
    box-shadow: 0 0 1px #29225c;
}

.address-edit .switch input:checked+.slider:before,
.pay .switch input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.address-edit .switch .slider.round,
.pay .slider.round {
    border-radius: 34px;
}

.address-edit .switch .slider.round:before,
.pay .slider.round:before {
    border-radius: 50%;
}

.pay .odeme-tamamla button {
    border-radius: 15px;
    background-color: #29225c;
    width: 100%;
    border: 0;
    margin-top: 50px;
    font-size: 14px;
}

/* pay finish */

/* drawermenu */

.drawer {
    /* position: fixed !important; */
    z-index: 5 !important;
    background-image: linear-gradient(to bottom right, rgb(205, 205, 205), rgb(252, 252, 252));
}

.drawermenu {
    background-image: linear-gradient(to bottom right, rgb(205, 205, 205), rgb(252, 252, 252));
    padding-top: 130px;
    padding-bottom: 140px;
    padding-left: 20px;
    padding-right: 20px;
    /* position: fixed !important; */
    z-index: 5 !important;
    top: 120px !important;
}

.drawermenu img {
    z-index: 5;
    height: 120px;
}

.drawermenu .puan span {
    font-size: 14px;
    z-index: 5;
    color: grey;
}

.drawermenu .urunler {
    margin-bottom: 20px;
    z-index: 5;
    transition: 1s;
}

.drawermenu .urunler .item {
    display: flex;
    justify-content: space-between;
    height: 25px;
    transition: 1s;
}

.drawermenu .urunler .arrowdown {
    margin-top: -7px;
    transition: 1s;
}

.drawermenu .urunler h5 {
    font-size: 16px;
}

.drawermenu .urunler .item .title {
    font-size: 13px;
    transition: 1s;
}

.drawermenu .urunler .submenu {
    text-align: left;
    margin-top: -5px;
    margin-bottom: 8px;
    transition: 1s;
}

.drawermenu .urunler .submenu span {
    font-size: 13px;
    color: grey;
    transition: 1s;
}

/* drawermenu finish */


/* filter */

.filter {
    background-color: #242d41;
    height: 35px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 9999;
    left: 0;
}

.filter div {
    align-items: center;
}

.filter img {
    height: 20px;
}

.filter span {
    font-size: 14px;
    color: white;
}

.modal-filter {
    padding: 10px;
}

.modal-filter button {
    width: 100%;
    background-color: #242d41;
}

/* filter finish */


/* basket */

.basket .tamamla {
    background-color: rgb(245, 245, 245);
    position: fixed;
    bottom: 60px;
    width: 100%;
    left: 0;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
}

.basket .tamamla .bosalt {
    border-radius: 10px 0px 0px 10px;
    border: 0;
    width: 100%;
    font-size: 14px;
}

.basket .tamamla .satin {
    border-radius: 0px 10px 10px 0px;
    background-color: #242d41;
    color: white;
    width: 100%;
    border: 0;
    font-size: 14px;
}

/* basket finish */

/* adres bilgileri */

.address-info .sao {
    display: flex;
    justify-content: center;
    margin-top: 90px;
    align-items: center;
}

.address-info .sao div {
    border: 1px solid grey;
}

.address-info .sao .line {
    height: 1px;
    border: 0.5px solid grey;
    width: 30px;
}

.address-info .sao .section {
    border-radius: 10px;
    font-size: 11px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.address-info .sao .select {
    background-color: #242d41;
    color: white;
    border: 0;
}

.address-info .tes-adres {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.address-info .tes-adres .newAddress {
    color: white;
    background-color: #29225c;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
}

.address-info .kargo {
    font-size: 13px;
}

.address-info .description {
    margin-top: 10px;
    font-size: 14px;
}

.address-info .tes-adres span {
    font-size: 13px;
}

.address-info .tes-adres small {
    font-size: 10px;
}

.address-info .tamamla button {
    background-color: #242d41;
    border-radius: 15px;
    border: 0;
    width: 86%;
    margin-left: 7%;
    margin-top: 30px;
    font-size: 14px;
}

/* adres bilgileri finish */


/* pay info */

.pay-info .sao {
    display: flex;
    justify-content: center;
    margin-top: 90px;
    align-items: center;
}

.pay-info .sao div {
    border: 1px solid grey;
}

.pay-info .sao .line {
    height: 1px;
    border: 0.5px solid grey;
    width: 30px;
}

.pay-info .sao .section {
    border-radius: 10px;
    font-size: 8px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.pay-info .sao .select {
    background-color: #242d41;
    color: white;
    border: 0;
}

.pay-info .info span {
    font-size: 11px;
}

.pay-info .info small {
    color: #ccc;
}

.pay-info .info .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pay-info .info .item div {
    width: 33%;
}

.pay-info .odeme-seklis {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pay-info .odeme-seklis span {
    font-size: 10px;
}

.pay-info .odeme-seklis div {
    border: 1px solid grey;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.pay-info .odeme-seklis .active {
    border-bottom: 5px solid grey;
    transition: 0.3s;
}

.pay-info .joker {
    margin-left: 25px;
    margin-top: 15px;
    font-size: 12px;
}

.pay-info .satissozlesmesi {
    margin-left: 25px;
    margin-top: 15px;
    font-size: 12px;
}

.pay-info .view .tamamla button {
    background-color: #242d41;
    border: 0;
    border-radius: 15px;
    font-size: 13px;
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
}

.pay-info .modal-dialog-centered {
    font-size: 12px;
}

.pay-info .havale {
    font-size: 13px;
    width: 86%;
    margin-left: 7%;
    margin-top: 20px;
    margin-bottom: 120px;
}

.pay-info .havale .select {
    width: 16px;
    height: 16px;
    border: 1px solid grey;
    border-radius: 8px;
    margin-right: 10px;
    margin-left: 10px;
}

.pay-info .havale .selected {
    width: 16px;
    height: 16px;
    border: 5px solid blue;
    border-radius: 8px;
    margin-right: 10px;
    margin-left: 10px;
}

.pay-info .havale .card {
    margin-bottom: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.pay-info .havale .tamamla button {
    background-color: #242d41;
    border: 0;
    border-radius: 15px;
    width: 86%;
    margin-left: 7%;
    margin-top: 20px;
    font-size: 14px;
}

.pay-info .cari-borc {
    margin-top: 20px;
    font-size: 13px;
}

.pay-info .cari-borc .card {
    padding: 10px;
    width: 86%;
    margin-left: 7%;
}

.pay-info .cari-borc .card .select {
    width: 16px;
    height: 16px;
    border: 5px solid blue;
    border-radius: 8px;
    margin-right: 10px;
    margin-left: 10px;
}

.pay-info .cari-borc .tamamla button {
    background-color: #242d41;
    border: 0;
    border-radius: 15px;
    width: 86%;
    margin-left: 7%;
    margin-top: 20px;
    font-size: 14px;
}

/* pay info finish */


/* SWİTCH */

.address-edit .switch,
.pay-info .switch,
.pay .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
}

.address-edit .switch input,
.pay-info .switch input,
.pay .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.address-edit .switch .slider,
.pay-info .switch .slider,
.pay .switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.address-edit .switch .slider:before,
.pay-info .switch .slider:before,
.pay .switch .slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 0px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.address-edit .switch input:checked+.slider,
.pay-info .switch input:checked+.slider,
.pay .switch input:checked+.slider {
    background-color: red;
}

.address-edit .switch input+.slider,
.pay-info .switch input:focus+.slider,
.pay .switch input:focus+.slider {
    box-shadow: 0 0 1px red;
}

.address-edit .switch input:checked+.slider:before,
.pay-info .switch input:checked+.slider:before,
.pay .switch input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.address-edit .switch .slider.round,
.pay-info .switch .slider .round,
.pay .slider.round {
    border-radius: 34px;
}

.address-edit .switch .slider.round:before,
.pay-info .switch .slider .round:before,
.pay .slider.round:before {
    border-radius: 50%;
}

.pay .odeme-tamamla button {
    border-radius: 15px;
    background-color: #242d41;
    width: 100%;
    border: 0;
    margin-top: 50px;
    font-size: 14px;
}

.pay .odeme-sekli,
.pay-info .odeme-sekli {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    font-size: 14px;
}

/* SWİTCH FİNİSH */



/* product-detail */

.product-detail .slider {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 70px;
}

.product-detail .slider img {
    height: 200px;
}

.product-detail .sepet-ekle button {
    background-color: #242d41;
    border: 0;
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
    border-radius: 15px;
}

.product-detail .adet {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.product-detail .adet svg {
    margin-top: 7px;
}

.product-detail .adet input {
    width: 40px;
}

.product-detail .adet div {
    margin-left: 10px;
    margin-right: 10px;
}

.product-detail .cargo {
    margin-top: 15px;
    font-size: 15px;
    text-align: center;
    font-family: 'Nunito-Bold';
}

.product-detail .cargo img {
    margin-right: 10px;
}

.product-detail .properties span {
    font-size: 14px;
}


/* product-detail finish */

.notimg {
    display: none;
}

.animationimg {
    display: block;
}

.animationimg .arti1 {
    animation: animated_div 2s 1;
    -moz-animation: animated_div 2s 1;
    -webkit-animation: animated_div 2s 1;
    -o-animation: animated_div 2s 1;
}

#btnControl {
    display: none;
}

.arti1 {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #242d41;
    color: white;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 15px;
    z-index: 9999999;
}

#btnControl:checked+label>.arti1 {
    z-index: 10000;
    transition: 2s;
    animation: animated_div 2s 1;
}

@keyframes animated_div {
    0% {
        position: absolute;
        margin-top: 0px;
        display: none;
        opacity: 1;
        z-index: 9999999;
    }

    25% {
        position: absolute;
        margin-top: 0px;
        opacity: 1;
        z-index: 9999999;
    }

    50% {
        position: absolute;
        margin-top: 0px;
        opacity: 0.5;
        z-index: 9999999;
    }

    100% {
        position: absolute;
        right: 0px;
        margin-top: -1000px;
        opacity: 0;
        z-index: 9999999;
    }
}


/* order-detail */

.order-detail .prints {
    margin-top: 80px;
    border: 1px solid rgb(186, 186, 186);
    width: 90%;
    margin-left: 5%;
    padding-bottom: 10px;
}

.order-detail .prints .card {
    width: 40%;
    margin-left: 30%;
}

.order-detail .fiyat-miktar .card {
    margin-top: 20px;
    width: 90%;
    margin-left: 5%;
}

.order-detail .fiyat-miktar .card small {
    font-size: 10px;
    color: grey;
}

.order-detail .fiyat-miktar .card b {
    font-size: 12px;
}

.order-detail .siparis-tutar .card {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
}

.order-detail .siparis-tutar .card b {
    font-size: 13px;
    color: rgb(116, 116, 116);
}

.order-detail .siparis-tutar .card small {
    color: grey;
    font-size: 11px;
}

.order-detail .teslimat .card {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
}

.order-detail .teslimat .card b {
    font-size: 13px;
    color: rgb(116, 116, 116);
}

.order-detail .teslimat .card small {
    font-size: 11px;
    color: grey;
}

.order-detail .odeme-yontem .card {
    margin-bottom: 80px;
    margin-top: 20px;
    width: 90%;
    margin-left: 5%;
    padding: 10px;
}

.order-detail .odeme-yontem .card b {
    font-size: 13px;
    color: rgb(116, 116, 116);
}

.order-detail .odeme-yontem .card small {
    font-size: 11px;
    color: grey;
    display: block;
}

/* order-detail finish */


/* address */

.my-address .basliklar {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

.my-address .basliklar div {
    background-color: white;
    height: 45px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-left: 2px;
    margin-right: 2px;
    border: 1px solid rgb(220, 220, 220);
}

.my-address .basliklar b {
    font-size: 12px;
}

.my-address .addresses {
    background-color: white;
    margin-top: 30px;
}

.my-address .addresses .item {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 30px;
    border-bottom: 1px solid rgb(220, 220, 220);
    padding-bottom: 5px;
    padding-top: 5px;
}

.my-address .addresses .item img {
    height: 18px;
}

.my-address button {
    width: 90%;
    margin-left: 5%;
    border-radius: 10px;
    font-size: 15px;
    background-color: #29225c;
    border: 0;
    margin-top: 30px;
}

/* address finish */


/* address edit */

.address-edit .duzenle {
    margin-top: 80px;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 100px;
}

.address-edit .duzenle input {
    margin-bottom: 10px;
}

.address-edit .duzenle span {
    font-size: 13px;
    color: grey;
}

.address-edit .duzenle button {
    background-color: #29225c;
    border-radius: 20px;
    border: 0;
    width: 100%;
    margin-top: 30px;
    font-size: 14px;
}

/* address edit finish */


/* profile */

.account .info {
    margin-top: 80px;
    margin-bottom: 100px;
    width: 90%;
    margin-left: 5%;
}

.account .info b {
    font-size: 14px;
}

.account .info button {
    background-color: #29225c;
    border: 0;
    border-radius: 15px;
    margin-top: 20px;
    font-size: 15px;
    width: 100%;
}

.account .info .forms div {
    margin-top: 8px;
    font-size: 13px;
    color: grey;
}

/* profile finish */


/* mypayments */

.my-payments .date-print {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.my-payments .date-print span {
    font-size: 14px;
}

.my-payments .date-print div {
    margin-left: 5px;
    margin-right: 5px;
}

.my-payments .date-print input {
    width: 100%;
}

.my-payments .date-print img {
    height: 28px;
    margin-bottom: 7px;
}

.my-payments .tables {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.my-payments .tables div {
    background-color: white;
    border: 1px solid rgb(220, 220, 220);
    height: 45px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-align: center;
}

.my-payments .tabless {
    margin-top: 20px;
    background-color: white;
    margin-bottom: 80px;
}

.my-payments .tabless .item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(186, 186, 186);
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.my-payments .tabless .item small {
    font-size: 10px;
}

.my-payments .bakiye {
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #29225c;
    color: white;
    font-size: 13px;
    width: 270px;
    text-align: center;
}

/* mypayments finish */

.header .basket {
    position: absolute;
    top: 3px;
    right: -5px;
    background-color: #29225c;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 10px;
}

.header .baskets {
    position: absolute;
    top: 14px;
    right: 6px;
    background-color: #29225c;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 10px;
}

.basket .deleteBasketModal {
    margin-left: -100px;
}

.logoImg {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.logoImg img {
    animation: box-shadow 2s infinite;
    transform: translate(0%, -50%);
}


@keyframes box-shadow {
    0% {
        box-shadow: 0 0 0 0 rgb(74, 70, 202), 0 0 0 0 rgb(70, 92, 202);
        padding: 10px;
        border-radius: 10px;
        height: 40px;
    }

    40% {
        box-shadow: 0 0 0 10px rgba(202, 170, 70, 0), 0 0 0 0 rgb(70, 79, 202);
        padding: 10px;
        border-radius: 5px;
        height: 50px;
    }

    80% {
        box-shadow: 0 0 0 10px rgba(202, 170, 70, 0), 0 0 0 10px rgba(202, 170, 70, 0);
        padding: 10px;
        border-radius: 10px;
        height: 50px;
    }

    100% {
        box-shadow: 0 0 0 0 rgba(202, 170, 70, 0), 0 0 0 10px rgba(202, 170, 70, 0);
        padding: 10px;
        border-radius: 5px;
        height: 40px;
    }
}

.leftBack {
    width: 100%;
    height: 100vh;
    z-index: 999999;
    opacity: 0.7;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
}

.leftDrawer {
    background-color: white;
    position: fixed;
    height: 100vh;
    z-index: 999999999999;
    width: 80%;
    top: 0;
    left: 0;
    overflow: auto;
}

.leftDrawer .profilInfo {
    padding: 5px 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.leftDrawer .urunler {
    padding: 10px;
}

.leftDrawer .urunler span {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}

.leftDrawer .profilImg {
    text-align: center;
    margin-top: 30px;
}

.leftDrawer .profilImg img {
    text-align: center;
    height: 100px;
}

.loadLeftMenuBack {
    background-color: black;
    opacity: 0.7;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.categories .brandItem {
    height: 136px;
    /* background-image: linear-gradient(to right, transparent, rgb(86, 75, 168), transparent); */
    border: 1px solid #29225c;
    margin: 10px;
    width: 76%;
    margin-left: 12%;
    position: relative;
}

.categories .brandItem .brandImg img {
    width: 90%;
    height: 88px;
    margin-left: 5%;
    margin-top: 8px;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    object-position: center;
}

.categories .brandItem .brandText {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
}

.categories .brandItem .brandText h4 {
    font-size: 16px;
}

.product-detail .brandName span {
    color: #29225c;
    letter-spacing: 6px;
    font-weight: 900;
    border: 1px solid #29225c;
    padding: 5px 10px;
    border-radius: 5px;
}

.product-detail .brandName span svg:first-child {
    margin-right: 20px;
    margin-top: -3px;
}

.product-detail .brandName span svg:last-child {
    margin-left: 16px;
    margin-top: -3px;
}